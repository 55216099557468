/** @format */

import React from "react";
import CompanyImg from "../../assets/images/company.png";
import Container from "../../components/Container/Container";

export default function Index() {
  return (
    <div className="md:py-[5rem] xl:px-[15rem] lg:px-[5rem] sm:py-[0rem] md:px-[2rem]">
      <div className="relative w-full flex flex-col md:flex-row gap-14">
        {/* IMAGE */}
        <div className="w-full md:w-1/2 relative">
          <img
            src={
              "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Crafting"
            }
            alt=""
            className="rounded-xl w-full h-full object-cover"
          />
          {/* CONTENT OVERLAY FOR SMALL SCREENS */}
          <div className="absolute inset-0 flex flex-col justify-center items-center bg-black bg-opacity-50 p-7 md:hidden gap-3">
            <h1 className="text-4xl sm:text-[4rem] text-white text-center font-medium font-avenir leading-snug tracking-wide">
              Crafting personalized legal solutions, fostering global impact
              through transparency
            </h1>
            <br />
            <p className="text-zinc-300 text-center text-2xl sm:text-2xl tracking-wider">
              At our firm, we specialize in crafting personalized legal
              solutions tailored to meet your unique needs. We believe in
              fostering a global impact through unwavering transparency,
              ensuring that our clients are always informed and empowered. Our
              commitment to clarity and customized service allows us to navigate
              complex legal landscapes effectively, delivering results that
              matter.
            </p>
          </div>
        </div>
        {/* CONTENT FOR MEDIUM AND LARGE SCREENS */}
        <div className="w-full md:w-1/2 border-y-2 border-gray-200  hidden md:flex flex-col justify-evenly 2xl:justify-center 2xl:gap-[3rem] p-2">
          <div>
            <h1 className="lg:text-[5rem] text-center font-medium sm:text-[3.5rem]">
              Crafting personalized legal solutions, fostering global impact
              through transparency
            </h1>
          </div>
          <div>
            <p className="lg:text-[2rem] text-center sm:text-[1.8rem]">
              At our firm, we specialize in crafting personalized legal
              solutions tailored to meet your unique needs. We believe in
              fostering a global impact through unwavering transparency,
              ensuring that our clients are always informed and empowered. Our
              commitment to clarity and customized service allows us to navigate
              complex legal landscapes effectively, delivering results that
              matter.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
