/** @format */

import React, { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import Navbar from "../NavBar/NavBar";

export default function Header() {
  const [showDrawer, setshowDrawer] = useState(false);
  const toggleDrawer = () => setshowDrawer(!showDrawer);

  const goToHomePath = () => {
    window.location.href = "/";
  };

  return (
    <div className="relative">
      <div className="flex justify-between items-center py-4 px-4 lg:px-[5rem] lg:py-[4rem]">
        <div className="lg:hidden flex-grow">
          <div className="text-center p-3">
            <img
              className="inline-block  h-[5rem] pl-[6%]"
              src={require("../../assets/images/headermiddlelogo.png")}
              alt="Logo"
            />
          </div>
        </div>
        <div className="lg:hidden">
          <MenuIcon
            onClick={toggleDrawer}
            sx={{ color: "#000", fontSize: "2.5rem" }}
          />
        </div>
        <div className="hidden lg:flex w-full items-center">
          {/* Left Logo */}
          <div
            onClick={goToHomePath}
            className="flex-shrink-0 w-[7rem] h-[7rem] cursor-pointer"
          >
            <img
              className="w-full h-full"
              src={
                "https://res.cloudinary.com/dmxbffrod/image/upload/v1722451375/Website/logo.png"
              }
              alt="Logo"
            />
          </div>
          {/* Spacer */}
          <div className="flex-grow"></div>
          {/* Main Logo */}
          <div className="flex-shrink-0 w-[24rem] h-[8rem] md:ml-[-4%]">
            <img
              className="w-full h-full"
              src={require("../../assets/images/headermiddlelogo.png")}
              alt="Main Logo"
            />
          </div>
          {/* Spacer */}
          <div className="flex-grow"></div>
        </div>
      </div>
      <Navbar showDrawer={showDrawer} toggleDrawer={toggleDrawer} />
    </div>
  );
}
