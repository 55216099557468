import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={color}
        d="M28.061 17.061l-9 9a1.503 1.503 0 01-2.125-2.125l6.439-6.436H5a1.5 1.5 0 110-3h18.375l-6.436-6.44a1.503 1.503 0 012.125-2.125l9 9a1.5 1.5 0 01-.003 2.126z"
      ></path>
    </svg>
  );
}

export default Icon;
