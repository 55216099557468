import React, { useState, useEffect, useRef } from "react";
import Xicon from "../../assets/icons/Xicon.jsx";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logo from "../../assets/images/headermiddlelogo.png";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const paths = [
  { title: "HOME", id: "", newTab: false },
  {
    title: "SERVICES",
    id: "services",
    newTab: false,
    subPaths: [
      { title: "INSOLVENCY & BANKRUPTCY", id: "insolvency" },
      { title: "COMPETITION & ANTI TRUST LAW", id: "competition-law" },
      { title: "REAL ESTATE", id: "real-estate" },
      { title: "DISPUTE RESOLUTION", id: "dispute-resolution" },
      { title: "CORPORATE & COMMERCIAL", id: "corporate-commercial" },
      { title: "CRIMINAL LITIGATION", id: "criminal-litigation" },
      { title: "DATA PROTECTION", id: "data-protection" },
      { title: "INTELLECTUAL PROPERTY", id: "intellectual-property" },
      { title: "View All Services", id: "allservices" },
    ],
  },
  { title: "OUR TEAM", id: "teams", newTab: false },
  { title: "OUR VALUES", id: "ourvalues", newTab: false },
  { title: "CAREERS", id: "careers" },
  { title: "BLOGS", id: "blogs", newTab: false },
  { title: "CONTACT", id: "contacts", newTab: false },
];

interface NavBarProps {
  showDrawer: boolean;
  toggleDrawer: () => void;
}

interface HoveredItems {
  [key: string]: boolean;
}

export default function Navbar({ showDrawer, toggleDrawer }: NavBarProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredItems, setHoveredItems] = useState<HoveredItems>({});
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const navbarRef = useRef<HTMLDivElement | null>(null);
  const servicesLinkRef = useRef<HTMLAnchorElement | null>(null);
  const [isServicesView, setIsServicesView] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const navigate = useNavigate();

  const goToHomePath = () => {
    window.location.href = "/";
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCloseDrawer = () => {
    toggleDrawer();
  };

  const isCurrentPathSelected = (id: string) => {
    const currentPath = window.location.pathname.split("/")[1];
    return (
      currentPath === id ||
      (id === "careers" && window.location.pathname.startsWith("/apply-now"))
    );
  };

  const handleMouseEnter = (id: string) => {
    setIsHovered(true);
    setHoveredItems((prev) => ({ ...prev, [id]: true }));
  };

  const handleTextEntered = (id: string) => {
    setHoveredItems((prev) => ({ ...prev, [id]: true }));
  };

  const handleTextNotHovered = (id: string) => {
    setHoveredItems((prev) => ({ ...prev, [id]: false }));
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleNavigateToService = () => {
    if (isMobile) {
      toggleDrawer();
    }
    window.location.href = "/services";
  };

  const handleServiceNavigation = (id: string) => {
    if (isMobile) {
      handleCloseDrawer();
    }
    if (id === "allservices") {
      navigate("/services", { state: { scrollTo: "allservices" } });
    } else {
      navigate(`/services/${id}`);
    }
  };

  const openServices = () => {
    setIsServicesView(true);
  };

  const backToMainMenu = () => {
    setIsServicesView(false);
  };

  const MainMenu = (
    <Box sx={{ width: "100%", maxWidth: 250 }} role="presentation">
      <List className="!mt-[2rem]">
        {paths.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => {
                if (item.title === "SERVICES") {
                  // openServices();
                } else {
                  if (isMobile) {
                    handleCloseDrawer();
                  }
                  navigate(`/${item.id}`);
                }
              }}
            >
              <p
                className="flex  text-black underline underline-offset-8
                decoration-[#941C1E] hover:no-underline tracking-wider text-[1.5rem] font-medium mb-[1.5rem]"
              >
                <span
                  //@ts-ignore
                  onClick={
                    item.title === "SERVICES"
                      ? () => {
                          navigate(`/${item.id}`);
                          handleCloseDrawer();
                        }
                      : null
                  }
                >
                  {item.title}
                </span>
                {item.title === "SERVICES" && (
                  <span onClick={openServices}>
                    <KeyboardArrowRightIcon className="!text-[2rem]" />
                  </span>
                )}
              </p>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const ServicesView = (
    <Box sx={{ width: "100%", maxWidth: 250 }} role="presentation">
      <List>
        <ListItem key="MainMenu" disablePadding>
          <ListItemButton onClick={backToMainMenu}>
            <p
              className="flex  text-black underline underline-offset-8
                decoration-[#941C1E] hover:no-underline tracking-wider text-[1.5rem] font-medium mb-[1.5rem]"
            >
              <KeyboardArrowLeftIcon className="!text-[2rem]" />
              MAIN MENU
            </p>
          </ListItemButton>
        </ListItem>

        {paths
          .find((p) => p.id === "services")
          ?.subPaths?.map((subPath, index) => (
            <ListItemButton
              key={index}
              sx={{ pl: 4 }}
              onClick={() => {
                handleServiceNavigation(subPath.id);
                handleCloseDrawer();
                handleCloseDrawer();
              }}
            >
              <p
                className="flex  text-black underline underline-offset-8
                decoration-[#941C1E] hover:no-underline tracking-wider text-[1.5rem] font-medium mb-[1.5rem]"
              >
                {subPath.title}
              </p>
            </ListItemButton>
          ))}
      </List>
    </Box>
  );

  return (
    <div data-aos="fade">
      <div
        ref={navbarRef}
        className="flex items-center text-Avenir font-medium justify-between w-full relative "
      >
        <Drawer
          open={showDrawer}
          onClose={toggleDrawer}
          anchor="left"
          sx={{
            "& .MuiDrawer-paper": {
              width: "100%",
              maxWidth: "100%",
              backgroundColor: "#fff",
              padding: "1rem",
              boxSizing: "border-box",
            },
          }}
        >
          <div className="flex flex-row pb-[1rem]">
            <img
              className="pl-[5rem]"
              src={
                "https://res.cloudinary.com/dmxbffrod/image/upload/v1722451375/Website/logo.png"
              }
              alt="Logo"
              style={{ maxWidth: "55%", height: "5rem", margin: "0 auto" }}
            />
            <IconButton onClick={handleCloseDrawer}>
              <Xicon />
            </IconButton>
          </div>
          <div className="h-1 w-100%">
            <hr className="bg-gray-200"></hr>
          </div>

          {isServicesView ? ServicesView : MainMenu}
        </Drawer>
        <div className="w-full lg:px-[5rem] hidden lg:grid grid-cols-7 ">
          {paths.map((path, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center gap-2 "
              onClick={
                path.title === "SERVICES"
                  ? (e) => e.preventDefault()
                  : undefined
              }
            >
              <a
                onClick={
                  path.title === "SERVICES" ? handleNavigateToService : () => {}
                }
                onMouseEnter={(e) => {
                  if (path.title === "SERVICES") {
                    handleMouseEnter(path.id);
                  } else {
                    handleMouseLeave();
                    handleTextEntered(path.id);
                  }
                }}
                onMouseLeave={() => handleTextNotHovered(path.id)}
                style={{
                  color: isCurrentPathSelected(path.id) ? "#941C1E" : "#000",
                  textDecorationThickness: "5px",
                }}
                target={path?.newTab ? "_blank" : "_self"}
                href={path?.newTab ? path.id : `/${path.id}`}
                className={`text-[1.7rem] font-medium tracking-wider ${
                  !isCurrentPathSelected(path.id) && "hover:!text-[#941C1E]"
                } rounded-lg underline-offset-17 z-20`}
              >
                {path.title}
              </a>

              {path.title === "SERVICES" && (
                <div
                  ref={dropdownRef}
                  onMouseLeave={handleMouseLeave}
                  className={`absolute left-0 top-[3.5rem] w-full  bg-white shadow-lg rounded-md border border-[#ddd] z-50 transition-all duration-300 ease-out ${
                    isHovered ? "opacity-100" : "opacity-0 hidden"
                  }`}
                  style={{
                    padding: "20px",
                    boxSizing: "border-box",
                  }}
                >
                  <div className="grid grid-cols-1 gap-x-4 gap-y-[40px] md:grid-cols-2 lg:grid-cols-4 mt-[2rem] tracking-wide">
                    {path.subPaths?.map((subPath, subIndex) => (
                      <div
                        key={subIndex}
                        className={`w-full font-medium  ${
                          subPath.title === "View All Services"
                            ? "col-span-full mt-[1rem] mb-[2rem] font-medium underline underline-offset-8"
                            : ""
                        }`}
                      >
                        <div
                          onClick={() => handleServiceNavigation(subPath.id)}
                          className="block cursor-pointer text-center text-[1.6rem] relative transition duration-300 ease-in-out underline-offset-8"
                          style={{ textDecoration: "none" }}
                        >
                          <span
                            className="hover:underline transition-all duration-300 ease-in-out text-[1.7rem]"
                            style={{ textDecorationColor: "#941C1E" }}
                          >
                            {subPath.title}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div
                style={{
                  background:
                    isCurrentPathSelected(path.id) || hoveredItems[path.id]
                      ? "#941C1E"
                      : "#fff",
                }}
                className={`w-full h-[4px] rounded-[1rem] ${
                  hoveredItems[path.id] &&
                  "hover:underline decoration-[#941C1E] transition-all duration-500 "
                } ${
                  !isCurrentPathSelected(path.id) &&
                  "hover:underline decoration-[#941C1E]"
                }`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
