import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      fill="none"
      viewBox="0 0 48 48"
    >
      <rect width="48" height="48" fill="#C70039" rx="24"></rect>
      <path
        fill="#fff"
        d="M34.5 24a1.476 1.476 0 01-.712 1.267L20.28 33.53a1.501 1.501 0 01-2.075-.517 1.487 1.487 0 01-.205-.75V15.737a1.487 1.487 0 01.761-1.296 1.498 1.498 0 011.519.028l13.508 8.263A1.476 1.476 0 0134.5 24z"
      ></path>
    </svg>
  );
}

export default Icon;
