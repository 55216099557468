import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M10.666 4a4 4 0 00-4 4v28l7-4 7 4 7-4 7 4V8a4 4 0 00-4-4h-20zm5 6a3 3 0 100 6 3 3 0 000-6zm12.414.586a2 2 0 00-2.828 0l-12 12a2 2 0 102.828 2.828l12-12a2 2 0 000-2.828zM25.666 20a3 3 0 100 6 3 3 0 000-6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
