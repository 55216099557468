import React from "react";
import DownArrow from "../../assets/icons/DownArr.jsx";

interface Props {
  title: string;
  image: string;
  subtitle: string;
  serices?: boolean;
}

export default function HeroContainer({ title, image, subtitle }: Props) {
  return (
    <div className="relative text-center">
      <img
        src={image}
        className="object-cover w-full h-[23vh] md:h-[40vh] lg:h-[79vh]"
        alt={title}
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 max-md:w-[90%]">
        <h1 className="tracking-wider drop-shadow-sm text-[2.8rem] md:text-[4rem] lg:text-[5.8rem] font-medium text-white uppercase">
          {title}
        </h1>

        <h3 className=" md:block text-2xl md:text-3xl lg:text-4xl text-white">
          {subtitle}
        </h3>
      </div>
      <div className="hidden lg:block absolute bottom-3 left-1/2 transform -translate-x-1/2">
        <DownArrow color1={"#E0E0E0"} color2={"#FDFEFE"} />
      </div>
    </div>
  );
}
