/** @format */

import React from "react";

interface Props {
  title: string;
  onClick: () => void;
  type?: "button" | "submit" | "reset";
}

export default function Index({ title, onClick, type }: Props) {
  return (
    <button
      type={type}
      onClick={onClick}
      className="border sm:p-3 font-semibold border-[#941C1E] transition-all duration-300  lg:border-black text-white bg-[#941C1E] lg:bg-white lg:text-black hover:border-[#C70039] hover:bg-[#C70039] hover:text-white sm:text-[1.8rem] text-[1.5rem] sm:px-[2rem] px-[1rem] py-[0.5rem] rounded-[0.1rem] lg:my-[3rem] "
    >
      {title}
    </button>
  );
}
