/** @format */

import React from "react";
import TermsAndConditionsBannerImage from "../../assets/images/TermsAndConditionsBannerImage.png";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { servicesData } from "../../constants/ServicesData";
import Container from "../../components/Container/Container";
import HeroContainer from "../../components/HeroContainer";
import RenderCards from "../../components/RenderCards";

export default function index() {
   return (
      <div>
         <HeroContainer
            title="Terms And Conditions"
            image={TermsAndConditionsBannerImage}
            subtitle="Please review our terms and conditions carefully to understand your rights and obligations when using our services."
         />
         <div className="flex justify-center items-center">
            <div className="mt-[4rem] w-[90%]">
               <p className="text-[1.8rem] p-14">
               While we strive to keep the information on this website accurate and up-to-date, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is strictly at your own risk.<br /><br />

The use of this website, including the submission of any forms or communication through this website, does not establish an attorney-client relationship.<br /><br />

Confidential or time-sensitive information should not be sent through this website.<br /><br />

By accessing this website, you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms and conditions, you must not use this website.<br /><br />

All content on this website, including text, graphics, logos, images, and software, is the property of LegalSols and is protected by intellectual property laws.<br /><br />

ASN ATTHIK shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of this website.<br /><br />

This website may contain links to third-party websites. ASN ATTHIK is not responsible for the content, privacy practices, accuracy, or opinions expressed on third-party websites.<br /><br />

We may collect personal information, including but not limited to, your name, contact information, and any other details you provide when filling out forms or communicating with us through this website.<br /><br />

The personal information collected may be used for the purpose of providing legal services, responding to inquiries, improving our website, and communicating with you. We will not sell, rent, or share your personal information with third parties for marketing purposes.<br /><br />

We are committed to ensuring the security of your personal information. While we take reasonable measures to protect your data, we cannot guarantee the security of information transmitted over the internet.<br /><br />

This website may use cookies to enhance your user experience. You can choose to disable cookies through your browser settings, but this may affect the functionality of the website.<br /><br />

ASN ATTHIK reserves the right to update or modify these terms and conditions at any time. By continuing to use the website after such modifications, you agree to be bound by the revised terms. We encourage you to review this Privacy Policy periodically.<br /><br />

These terms and conditions are governed by the laws of India. Any dispute arising out of or in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts in Chennai, TamilNadu.<br /><br />

If you have any questions or concerns about this Privacy Policy or the handling of your personal information, please contact us at admin@asnatthiklegal.com

               </p>
            </div>
         </div>
      </div>
   );
}
