import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <g  fill={color} clipPath="url(#clip0_888_346)">
        <path d="M34.777 8.889h-10v27.778H37V11.11a2.222 2.222 0 00-2.223-2.222zm-5.555 18.889H27v-2.223h2.222v2.223zm0-5.556H27V20h2.222v2.222zm0-5.556H27v-2.222h2.222v2.222zm4.444 11.112h-2.222v-2.223h2.222v2.223zm0-5.556h-2.222V20h2.222v2.222zm0-5.556h-2.222v-2.222h2.222v2.222zM20.2 3.333H7.133A2.356 2.356 0 004.777 5.69v30.978h5.556v-3.334H17v3.334h5.555V5.689A2.356 2.356 0 0020.2 3.333zm-9.867 24.445H8.11v-2.222h2.222v2.222zm0-5.556H8.11V20h2.222v2.222zm0-5.555H8.11v-2.222h2.222v2.222zm0-5.556H8.11V8.89h2.222v2.222zm4.444 16.667h-2.222v-2.222h2.222v2.222zm0-5.556h-2.222V20h2.222v2.222zm0-5.555h-2.222v-2.222h2.222v2.222zm0-5.556h-2.222V8.89h2.222v2.222zm4.445 16.667H17v-2.222h2.222v2.222zm0-5.556H17V20h2.222v2.222zm0-5.555H17v-2.222h2.222v2.222zm0-5.556H17V8.89h2.222v2.222z"></path>
      </g>
      <defs>
        <clipPath id="clip0_888_346">
          <path
            fill="#fff"
            d="M0 0H40V40H0z"
            transform="translate(.333)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
