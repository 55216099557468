/** @format */

import React from "react";
import Container from "../../components/Container/Container";

interface ServicesCardProps {
  title: string;
  subtitle: string;
  icon: any;
}

export default function ServiceCard({
  title,
  subtitle,
  icon,
}: ServicesCardProps) {
  return (
    <div className="flex flex-col gap-[1.3rem] items-center md:w-[100vw] lg:w-full  p-[2rem] ">
      <div className="w-full h-[25rem]">
        <img className="w-[100%] h-[100%] rounded-xl" src={icon} />
      </div>
      <div className="  h-[50%] flex flex-col gap-4 ">
        <p className="lg:text-[2.5rem] text-[2rem] w-fit mx-auto text-[#000000] font-medium text-center hover:text-[#941C1E] hover:underline max-md:underline cursor-pointer underline-offset-8 decoration-[#941C1E]">
          {title}
        </p>
        <p className="text-[1.7rem] text-center font-medium">{subtitle} </p>
      </div>
    </div>
  );
}
