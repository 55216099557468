/** @format */

// Form.tsx
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "../../components/ContactInputs/Input";
import axios from "axios";
import { BACKEND_URL } from "../../definitons";
import toast from "react-hot-toast";
import { AUTHORIZATION_KEY } from "../../utils/definitons";

interface FormValues {
   name: string;
   email: string;
   message: string;
}

const formFields = [
   { label: "NAME", name: "name", placeholder: "Enter Name", type: "text" },
   {
      label: "EMAIL ADDRESS",
      name: "email",
      placeholder: "Enter Email Address",
      type: "email",
   },
   {
      label: "TYPE MESSAGE",
      name: "message",
      placeholder: "Enter Message Here",
      type: "text",
   },
] as const;

const validationSchema = Yup.object({
   name: Yup.string().required("Name is required"),
   email: Yup.string().email("Invalid email address").required("Email is required"),
   message: Yup.string().required("Message is required"),
});

const onSubmitForm = (values: any) => {
   axios
      .post(`${BACKEND_URL}`, values, {
         headers: {
            Authorization: AUTHORIZATION_KEY,
         },
      })
      .then((res) => {
         toast.success("Thank you for contacting us!");
      });
};

const Form: React.FC = () => {
   const formik = useFormik<FormValues>({
      initialValues: {
         name: "",
         email: "",
         message: "",
      },
      validationSchema,
      onSubmit: (values) => {
         onSubmitForm({ ...values, type: "contact_us" });
      },
   });

   return (
      <iframe
         data-tally-src="https://tally.so/embed/n9lLD4?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
         loading="lazy"
         width="100%"
         height="216"
         title="Newsletter subscribers"></iframe>
   );
};

export default Form;
