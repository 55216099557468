/** @format */

import React from "react";
import moment from "moment";

interface BlogCardProps {
  image: any;
  title: string;
  details: string;
  onClick: () => void;
  date: any;
}

export default function BlogCard({
  image,
  title,
  details,
  onClick,
  date,
}: BlogCardProps) {
  return (
    <div
      onClick={onClick}
      className="flex  md:gap-[2rem] gap-[1rem] flex-col cursor-pointer overflow-hidden sm:w-[27rem] max-sm:max-w-[40rem] w-[90%] md:w-[40rem] md:h-[55rem] sm:h-[50rem] h-auto max-sm:mb-[1rem]"
    >
      <div className="h-[20rem] md:h-[25rem] overflow-hidden">
        <img
          className="w-full h-full object-cover overflow-hidden"
          src={image}
          alt="blog"
        />
      </div>
      <p className="text-[1.7rem] mt-2 text-center max-sm:font-medium">
        {moment(date).format("DD MMMM YYYY").toUpperCase()}
      </p>
      <p className="text-[2rem] font-medium text-center max-md:text-[#941C1E] max-md:decoration-[#941C1E] max-sm:underline">
        {title}
      </p>
      <div className="">
        <p className=" line-clamp-4 text-[1.5rem] text-center whitespace-wrap text-ellipsis overflow-hidden">
          {details}
        </p>
      </div>
    </div>
  );
}
