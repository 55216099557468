/** @format */

import React, { useState } from "react";
import AnimationCard from "../AnimationCard/AnimationCard";
interface ValueCardProps {
  title: string;
  subtitle: string;
  icon: any;
  onClick?: () => void;
}

export default function ValueCard({
  title,
  icon,
  subtitle,
  onClick,
}: ValueCardProps) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="flex flex-col gap-[1.3rem] items-center w-[35rem] p-[2rem]"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative w-[30rem] h-[40rem] ">
        <div className="w-[30rem] h-[40rem]">
          <img className="-z-10 w-[100%] h-[100%]" src={icon} alt="" />
        </div>
        <div className="w-[26rem] h-[36rem] mx-auto my-[1.8rem] absolute inset-0 top-0 left-0 flex justify-center items-center">
          <AnimationCard
            classname={"w-[26rem] h-[36rem] "}
            button={true}
            buttonName={"Learn more"}
            onClick={onClick}
            hovered={isHovered}
          />
        </div>
      </div>
      <p className="lg:text-[2.3rem] text-[2rem] tracking-wide whitespace-nowrap font-medium hover:text-[#941C1E] hover:underline max-md:underline  decoration-[#941C1E] underline-offset-8  lg:no-underline md:no-underline cursor-pointer text-[#000000] hover">
        {title}
      </p>
      <p className="text-[1.6rem] text-center font-normal tracking-wide">
        {subtitle}
      </p>
    </div>
  );
}
