import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <g clipPath="url(#clip0_888_163)">
        <path
        fill={color}
          d="M40.4 16.86l-3.987-7.977a2.5 2.5 0 00-3.355-1.118l-3.886 1.943-8.186-2.166a1.272 1.272 0 00-.64 0l-8.187 2.166-3.885-1.943a2.5 2.5 0 00-3.355 1.118L.93 16.859a2.5 2.5 0 001.118 3.355l4.218 2.11 8.67 6.193c.128.09.272.157.424.195l10 2.5a1.25 1.25 0 001.188-.328l6.25-6.25 2.356-2.356 4.128-2.064A2.5 2.5 0 0040.4 16.86zm-8.576 5.215l-5.377-4.306a1.25 1.25 0 00-1.669.095c-2.783 2.803-5.884 2.448-7.862 1.198l6.756-6.562h4.97L32.894 21l-1.07 1.074zm-6.543 6.54l-9.08-2.27-7.688-5.49 4.375-8.75 7.778-2.062 1.531.405-7.031 6.825-.013.014a2.5 2.5 0 00.425 3.877c3.213 2.051 7.09 1.719 10.142-.781l4.32 3.47-4.759 4.762zm-4.018 5.438a1.25 1.25 0 01-1.516.91l-6.517-1.63a1.235 1.235 0 01-.424-.196l-4.117-2.94a1.25 1.25 0 011.453-2.032l3.924 2.803 6.287 1.57a1.25 1.25 0 01.91 1.515z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_888_163">
          <path
            fill="#fff"
            d="M0 0H40V40H0z"
            transform="translate(.666)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
