import React from "react";
import { useNavigate } from "react-router-dom";
import AnimationCard from "../AnimationCard/AnimationCard";

interface ValueCardProps {
  title: string;
  subtitle: string;
  icon: string;
}

export default function ValueCard({ title, icon, subtitle }: ValueCardProps) {
  return (
    <div className="relative flex flex-col gap-8 items-center w-full lg:w-[50rem] 2xl:w-[60rem] p-8">
      <div className="relative w-full lg:w-full h-90%">
        <img
          className="w-full h-auto rounded-lg object-cover"
          src={icon}
          alt={title}
        />
      </div>
      <div className="flex flex-col items-center gap-6">
        <p className="lg:text-[2.8rem] text-[2.5rem] text-center font-medium cursor-pointer lg:text-[#941C1E] text-[#000000] uppercase">
          {title}
        </p>
        <p className="lg:text-[1.9rem] text-[1.7rem] text-center font-normal tracking-normal">
          {subtitle}
        </p>
      </div>
    </div>
  );
}
