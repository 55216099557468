import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      fill="none"
      viewBox="0 0 20 16"
    >
      <path
        fill="#941C1E"
        d="M2 16c-.55 0-1.02-.196-1.412-.587A1.93 1.93 0 010 14V2C0 1.45.196.98.588.588A1.93 1.93 0 012 0h16c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v12c0 .55-.196 1.021-.587 1.413A1.92 1.92 0 0118 16H2zm8-7L2 4v10h16V4l-8 5zm0-2l8-5H2l8 5zM2 4V2v12V4z"
      ></path>
    </svg>
  );
}

export default Icon;
