/** @format */

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BACKEND_URL, AUTHORIZATION_KEY } from "../../utils/definitons";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Skeleton } from "@mui/material";

export default function Index() {
   const { id: blog_id } = useParams();

   const fetchBlog = () => {
      return axios
         .post(
            `${BACKEND_URL}/leglsols-fetch-individual-blogs`,
            {
               blog_id,
            },
            {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: AUTHORIZATION_KEY,
               },
            },
         )
         .then((res) => res.data);
   };

   const { data: blogData, isLoading } = useQuery({
      queryKey: ["blogs", blog_id],
      queryFn: fetchBlog,
   });

   useEffect(() => {
      if ("scrollRestoration" in window.history) {
         window.history.scrollRestoration = "manual";
      }
      window.scrollTo(0, 0);
   }, []);

   useEffect(() => {
      fetchBlog();
   }, []);

   return (
      <div className="flex items-center justify-center mt-[5rem]">
         {isLoading ? (
            <div className="items-center justify-center flex flex-col w-full">
               <Skeleton
                  variant="rectangular"
                  width={"40%"}
                  animation="wave"
                  height={50}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "2rem",
                  }}
               />
               <Skeleton
                  variant="rectangular"
                  width={"80%"}
                  animation="wave"
                  height={30}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "2rem",
                     marginTop: "4rem",
                  }}
               />
               <Skeleton
                  variant="rectangular"
                  width={"80%"}
                  animation="pulse"
                  height={30}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "2rem",
                  }}
               />
               <Skeleton
                  variant="rectangular"
                  width={"80%"}
                  animation="wave"
                  height={30}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "2rem",
                  }}
               />
               <Skeleton
                  variant="rectangular"
                  width={"80%"}
                  animation="wave"
                  height={30}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "2rem",
                  }}
               />
               <Skeleton
                  variant="rectangular"
                  width={"80%"}
                  animation="wave"
                  height={30}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "2rem",
                  }}
               />
               <Skeleton
                  variant="rectangular"
                  width={"80%"}
                  animation="wave"
                  height={30}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "2rem",
                  }}
               />
               <Skeleton
                  variant="rectangular"
                  width={"80%"}
                  animation="wave"
                  height={30}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "2rem",
                  }}
               />
               <Skeleton
                  variant="rectangular"
                  width={"80%"}
                  animation="wave"
                  height={30}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "2rem",
                  }}
               />
               <Skeleton
                  variant="rectangular"
                  width={"80%"}
                  animation="wave"
                  height={30}
                  style={{
                     borderRadius: "1rem",
                     marginBottom: "12rem",
                  }}
               />
            </div>
         ) : (
            <div
               className="lg:w-[90%] w-[95%] text-[2rem]"
               //@ts-ignore
               dangerouslySetInnerHTML={{ __html: blogData?.html_content as string }}></div>
         )}
      </div>
   );
}
