/** @format */
import CareerCard from "../../components/CareerCard";
import HeroContainer from "../../components/HeroContainer";
import RenderCards from "../../components/RenderCards";
const CareerData = [
   [
      {
         id: 1,
         icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Careers/d6lvglynzctpivxap22i",
         title: "Internship",
         subtitle: "Gain hands-on experience and mentorship in a dynamic legal environment, preparing you for a successful career in law",
      },
      {
         id: 2,
         icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Careers/akhqmdehh8mahnuzktiu",
         title: " Jobs",
         subtitle: "Join our team of dedicated legal professionals, where your expertise shapes justice and your career thrives.",
      },
   ],
];

export default function Index() {
   return (
      <div>
         <HeroContainer
            title="Careers"
            image={"https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/careersBannerImage"}
            subtitle="We offer a world of growth and opportunity. This is an accessible workplace. Persons with disabilities are welcome to apply."
         />

         <RenderCards
            data={CareerData}
            renderChildren={(card: any) => {
               return (
                  <CareerCard
                     key={card.id}
                     icon={card.icon}
                     title={card.title}
                     subtitle={card.subtitle}
                     id={card.id}
                  />
               );
            }}
         />
      </div>
   );
}
