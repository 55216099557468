import React from "react";
import PlayIcon from "../../assets/icons/PlayIcon";
import { useNavigate } from "react-router-dom";

interface AnimationCardProps {
  button: boolean;
  classname: string;
  buttonName: string;
  id?: number;
  onClick?: (id?: number) => void;
  hovered?: boolean;
}

export default function AnimationCard({
  button,
  classname,
  buttonName,
  onClick,
  id,
  hovered,
}: AnimationCardProps) {
  const navigate = useNavigate();

  return (
    <div
      className={`${classname} z-10 relative flex flex-col gap-[1rem] items-center justify-center transition-all duration-300 ease-out ${
        hovered ? " opacity-100" : " opacity-0 "
      }`}
    >
      <div className="absolute inset-0 -z-10 bg-[#941C1E] opacity-50 rounded-lg"></div>
      {button ? (
        <button
          onClick={() => onClick?.(id)}
          className="z-10 text-[1.5rem] bg-[#941C1E] text-[#fff] font-bold py-3 px-4 rounded"
        >
          {buttonName}
        </button>
      ) : (
        <div className="flex flex-col gap-[1rem] items-center">
          <p className="text-[2rem] font-semibold text-[#fff]">
            ABOUT LEGLSOLS
          </p>
          <PlayIcon />
        </div>
      )}
    </div>
  );
}
