import React from "react";

interface LocationCardProps {
  image: string;
  locationName: string;
}

const LocationCard: React.FC<LocationCardProps> = ({ image, locationName }) => {
  return (
    <div className="relative sm:w-[180px] sm:h-[180px] h-[170px] md:w-[300px] md:h-[300px] rounded-lg overflow-hidden">
      <img src={image} alt="locations" className="w-full h-full object-cover" />
      <div className="absolute inset-0 bg-black bg-opacity-35 rounded-lg flex  items-center justify-center">
        <span className="text-white text-[1.8rem] md:text-[2.3rem] font-medium">
          {locationName}
        </span>
      </div>
    </div>
  );
};

export default LocationCard;
