/** @format */

import DownArrow from "../../assets/icons/DownArr.jsx";

export default function index() {
  return (
    <div className="relative w-full md:h-[75vh] h-[90vh] lg:h-[78vh] ">
      <img src={"https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/homeBannerImage"} className="w-full h-[100%]  object-cover" />
      <div className="absolute md:top-1/2 top-[45%] w-full  left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col items-center">
        <p className="text-center text-[#000000] text-3xl lg:text-[2.5rem] md:text-2xl   font-medium">
          We Offer
        </p>
        <br />
        <p className="lg:text-[6.3rem] text-center font-medium md:text-[4rem] text-[3rem] text-[#941C1E]">
          BEYOND LEGAL SOLUTIONS
        </p>
        <p className="lg:text-[2.5rem] lg:w-[50%] md:text-[2rem] font-medium text-[#000000] text-center text-[1.8rem]">
          We don't just provide legal solutions; we craft pathways to success,
          innovation, and unparalleled peace of mind..
        </p>
      </div>
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2">
        <DownArrow color2={"#7D848A"} color1={"#000000"} />
      </div>
    </div>
  );
}
