/** @format */

import React, { useEffect } from "react";
import HeroContainer from "../../components/HeroContainer";
import ContactImg from "../../assets/images/contactBanner.png";
import Form from "../../components/ContactForm/Form";
import Map from "../../assets/images/map.png";
import Container from "../../components/Container/Container";
import MapSm from "../../assets/images/MapSmall.png";
interface OfficeDetail {
  title: string;
  info: React.ReactNode;
}

const officeDetails: OfficeDetail[] = [
  {
    title: "ADDRESS",
    info: (
      <div style={{ textDecoration: "underline" }}>
        100/1E, Dr Radhakrishnan Road,
        <br />
        Mylapore, Chennai 600004 <br />
        India
      </div>
    ),
  },
  {
    title: "OFFICE HOURS",
    info: (
      <>
        Monday to Saturday
        <br />
        9:00 am to 9:00 pm IST
        <br />
        <br />
        Sunday
        <br />
        9:00 am to 12 noon IST
      </>
    ),
  },
  {
    title: "CONTACT",
    info: (
      <div style={{ textDecoration: "underline" }}>
        <a href="tel:+918939891916" style={{ textDecoration: "underline" }}>
          +91 8939891916
        </a>
        <br />
        <br />
        <a
          href="mailto:hello@asnatthiklegal.com"
          style={{ textDecoration: "underline" }}
        >
          hello@asnatthiklegal.com
        </a>
      </div>
    ),
  },
];

const Index: React.FC = () => {
  const isMobileView = window.innerWidth;
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HeroContainer
        image={
          "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/contactUsBannerImage"
        }
        title="CONTACT"
        subtitle="We’re here to assist you. Reach out to discuss your legal needs and find out how we can help"
      />
      <Container>
        <div className="flex flex-col w-full gap-12 ">
          {/* Form Section */}
          <div className="w-full my-[5rem]">
            <Form />
          </div>
          {/* Office Details Title */}
          <div className="w-full text-center ">
            <h1 className="text-4xl md:text-4xl mb-[1.5rem] font-medium lg:text-5xl text-[#941C1E] ">
              OFFICE DETAILS
            </h1>
          </div>
          {/* Map Image */}
          <div className="w-full">
            <img
              className="w-full h-auto"
              src={isMobileView > 768 ? Map : MapSm}
              alt="Map"
            />
          </div>
          {/* Office Details */}
          <div className="w-full  flex flex-wrap justify-between my-[2rem] gap-[2rem] lg:gap-0">
            {officeDetails.map((detail, index) => (
              <div
                key={index}
                className={`flex flex-col gap-[1.5rem] items-center text-center w-full md:w-1/3 ${
                  detail.title === "OFFICE HOURS" &&
                  "md:border-r-2 md:border-l-2"
                }`}
              >
                <h3 className="text-3xl md:text-2xl lg:text-4xl font-semibold mb-4">
                  {detail.title}
                </h3>
                <p className="text-2xl md:text-xl lg:text-[1.5rem] tracking-wide !leading-[2rem] font-normal">
                  {detail.info}
                </p>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Index;
