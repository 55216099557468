/** @format */

import Motor from "../assets/images/services/Motor.png";

export const servicesData = [
   [
      {
         id: 1,
         icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Insolvency%20and%20Bankruptcy",
         title: "INSOLVENCY & BANKRUPTCY",
         subtitle:
            "Our legal team guides the creditors, debtors, and resolution professionals in acquisitions, deal structuring, drafting the resolution plan, conducting legal due diligence, conducting meetings with resolution professionals to address concerns and clarify queries and represent them before the Tribunals.",
      },
      {
         id: 2,
         icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Real%20estate",
         title: "REAL ESTATE",
         subtitle:
            "We guide our clients in Real estate transactions by offering comprehensive legal counsel in property acquisitions, leasing, and development, dealings , RERA Compliance, Due diligence, Project development and Venture Capitals.",
      },
   ],
   [
      {
         id: 3,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403083/Leglsols/services/w4yqx3ag3de2awjdxcdc.jpg",
         title: "CORPORATE & COMMERCIAL",
         subtitle:
            "Providing end-to-end corporate and commercial legal services, we assist clients in structuring transactions, drafting contracts, and navigating regulatory compliance. Also assists in commercial contracts ranging from joint ventures and strategic alliances, Software licensing arrangements, product development, manufacturing and supply alliances.",
      },
      {
         id: 4,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403067/Leglsols/services/jifr8ar35apkk8rim3tb.jpg",
         title: "DATA PROTECTION",
         subtitle:
            "Advice and defend the clients in data processing agreements and other associated documents, data security and breach notification, data retention, defense against investigations and proceedings regarding privacy/data protection violations or security failures and blockchain, internet of things, cloud, artificial intelligence.",
      },
   ],
   [
      {
         id: 5,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403078/Leglsols/services/y4k4q7w4n6ohsjryy2lb.jpg",
         title: "COMPETITION & ANTI TRUST LAW",
         subtitle:
            "We defend the clients facing the full spectrum of charges in private and public damages litigation, competitor actions, class actions, and administrative actions in competition law and resolve competition-related disputes and issues arising from M&A transactions.",
      },
      {
         id: 6,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403089/Leglsols/services/izmyijibvkfsij5hcorf.jpg",
         title: "DISPUTE RESOLUTION",
         subtitle:
            "Enabling the parties to find admissible solutions to their conflicts outside of traditional legal / court proceedings, but are governed by different rules . This includes Arbitration , Mediation, negotiation and Conciliation across a spectrum of commercial, civil, and regulatory disputes.",
      },
   ],
   [
      {
         id: 7,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403085/Leglsols/services/nhmzrfz423obi1cpki8v.jpg",
         title: "INTELLECTUAL PROPERTY",
         subtitle:
            "Our legal team guides clients in Patent, Copyright, Trademark, Industrial Design and Geographical indicators.We provide end to end services from filing applications to litigation services. Comprehensive services including patent filing, trademark registration, and enforcement, offering clients a robust defense against infringement.",
      },
      {
         id: 8,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403067/Leglsols/services/vssk6kqkxm4z3vaaud6w.jpg",
         title: "MATRIMONIAL & FAMILY LITIGATION",
         subtitle:
            "We provide legal assistance and represent clients in divorce matters, restitution of conjugal rights, maintenance issues for spouse and children, dowry demands cases, child custody. We provide legal solutions with respect to Hindu Laws, Muslim laws,Marriages under the Special Marriage Act.",
      },
   ],
   [
      {
         id: 9,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403077/Leglsols/services/wthjde1aytitpupu0qhl.jpg",
         title: "TAXATION",
         subtitle:
            "Providing services relating to tax assessments, tax advisory, structuring and investment strategy, due diligence and litigation. Assistance in representations before the CIT(A), Dispute Resolution Panel (DRP) and Income Tax Appellate Tribunal (ITAT) , Authority for Advance Rulings (AAR), Assisting counsels in preparing/representing appeals/petitions before high courts and Supreme Court.",
      },
      {
         id: 10,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403076/Leglsols/services/wugqhjbzhphukshsxnje.jpg",
         title: "ENVIRONEMNT LITIGATION",
         subtitle:
            "We represent and assist clients with Environment compliance and due diligence. We guide the clients in obtaining environment clearance, licenses in SEZ zones, renewal of environmental permits and CSR requirements.",
      },
   ],
   [
      {
         id: 11,
         icon: Motor,
         title: "MOTOR VEHICLE ACCIDENT LAW",
         subtitle:
            "We provide legal assistance in the cases of Insurance claims, Investigate the details of your accident, Gather all relevant information and proof of damages, Build and file a personal injury claim and Negotiate for a fair settlement.",
      },
      {
         id: 12,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403093/Leglsols/services/euyfnovhydr0i806cgbv.jpg",
         title: "PRIVATE CLIENT & ESTATE PLANNING",
         subtitle:
            "We advise the Clients in Multi-Jurisdiction Succession Planning, Incentive Structuring, Migration by HNIs, Will Drafting and Execution, Advisory on Reconciliation of Indian and Foreign Laws, High Value Offshore Investments and Testamentary Trusts and Advisory to trustees and Wealth Managers.",
      },
   ],
   [
      {
         id: 13,
         icon: "https://res.cloudinary.com/dxezkqczp/image/upload/v1722403086/Leglsols/services/l8qrgdnln9qeseauk0hu.jpg",
         title: "CRIMINAL LITIGATION",
         subtitle:
            "We represent clients involved in White collar crimes including economic offenses, money laundering Act, Prevention of corruption Act, Bails including regular and anticipatory bail in various district courts and high courts, criminal appeal, criminal revision, quashing of FIRs , drafting and filing complaints before the police authorities / courts and criminal cyber complaints.",
      },
   ],
];
