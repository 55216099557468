/** @format */

import { useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import AnimationCard from "../AnimationCard/AnimationCard";

interface CareerCardProps {
  title: string;
  subtitle: string;
  icon: string;
  id: number;
  onClick?: () => void;
}

export default function CareerCard({
  title,
  icon,
  subtitle,
  onClick,
  id,
}: CareerCardProps) {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();

  const handleApplyNowClick = (id: number) => {
    if (id === 1) {
      window.location.href = "/careers/apply-now/internship";
    } else if (id === 2) {
      window.location.href = "/careers/apply-now/jobs";
    }
  };

  return (
    <div className="relative flex flex-col gap-[1.3rem] items-center w-[100vw] lg:w-[50rem] p-[2rem] pb-[4rem] ">
      <div
        className="relative w-full h-full xl:h-full md:h-[30rem] lg:h-full lg:w-full"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img className="w-[100%] h-[100%] rounded-lg" src={icon} alt={title} />

        <div className="absolute inset-0 flex items-center justify-center">
          <AnimationCard
            classname={"w-[90%] h-[90%] rounded-lg"}
            button={true}
            buttonName={"Apply now"}
            onClick={() => handleApplyNowClick(id)}
            hovered={isHovered}
          />
        </div>
      </div>
      <div className="h-[40%] flex flex-col items-center gap-8 justify-between">
        <p className="lg:text-[2.8rem] text-[3rem] underline text-center font-medium cursor-pointer text-[#000000] uppercase hover:text-[#941C1E] hover:underline underline-offset-8 decoration-[#941C1E] lg:no-underline">
          {title}
        </p>
        <p className="lg:text-[1.7rem] text-[1.7rem] font-medium text-center">
          {subtitle}
        </p>
      </div>
    </div>
  );
}
