/** @format */

import React, { useState } from "react";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import MailIcon from "../../assets/icons/MailIcon";
import Phoneicon from "../../assets/icons/PhoneIcon";
import AnimationCard from "../AnimationCard/AnimationCard";
import { useNavigate } from "react-router-dom";
import { number } from "yup";

export interface ProfileCardProps {
   image: any;
   designation: string;
   name: string;
   role: string;
   phone: string;
   mail: string;
   index?: number;
   onClick?: () => void;
}

export default function ProfileCard({ image, designation, name, phone, mail, index, role, onClick }: ProfileCardProps) {
   const navigate = useNavigate();
   const [isHovered, setIsHovered] = useState(false);

   return (
      <div className="flex flex-col justify-center items-center gap-8 py-5 lg:py-24">
         <div
            className="relative"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <div className="w-full max-w-md h-[45vh] lg:w-full">
               <img
                  className="w-full h-full object-cover rounded-lg shadow-lg"
                  src={image}
                  alt="profile"
               />
            </div>
            <div className={`absolute inset-0 flex justify-center items-center ${isHovered && "bg-black bg-opacity-50"} rounded-lg`}>
               <AnimationCard
                  classname="w-[90%] h-[90%]"
                  button={true}
                  buttonName={"View Full Profile"}
                  onClick={onClick}
                  hovered={isHovered}
               />
            </div>
         </div>

         <p className="text-3xl md:text-2xl lg:text[3.3rem] font-medium">
            {designation}
            {` (${role})`}
         </p>
         <p
            onClick={onClick}
            className={`text-4xl underline  lg:no-underline font-bold md:text-4xl lg:text-[3.5rem]  cursor-pointer hover:text-[#941C1E] tracking-wide decoration-[#941C1E] hover:underline underline-offset-8  ${
               isHovered && "decoration-[#941C1E] hover:underline underline-offset-8 text-[#941C1E]"
            }`}>
            {name}
         </p>
         <a
            href={`tel:${phone}`}
            className="flex cursor-pointer items-center gap-2 text-3xl underline font-medium hover:no-underline ">
            <PhoneIcon /> {phone}
         </a>
         <div className="flex flex-col items-center gap-2">
            <a
               href={`mailto:${mail}`}
               className="flex items-center gap-2 text-3xl underline font-medium hover:no-underline">
               <MailIcon />
               <p>{mail}</p>
            </a>
         </div>
      </div>
   );
}
