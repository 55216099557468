import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
     fill={color}
        d="M3.333 36.667v-3.334S11.666 30 20 30c8.333 0 16.666 3.333 16.666 3.333v3.334H3.333zm15.5-21.5c-2-6.5-12.167-5-12.167-5s.334 13 9.834 11C15.833 16.333 13.333 15 13.333 15c4.667 0 5 5.667 5 5.667v7.666h3.333v-7s0-6.5 5-8.166c0 0-3.333 5-3.333 8.333C35 22.667 35 6.667 35 6.667S20.166 5 18.833 15.167z"
      ></path>
    </svg>
  );
}

export default Icon;
