import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <g clipPath="url(#clip0_1370_92)">
        <path
          fill={color}
          fillRule="evenodd"
          d="M11.912 17.903a4.285 4.285 0 010-6.063L22.155 1.6a4.286 4.286 0 016.063 0l4.523 4.526a4.286 4.286 0 010 6.06l-3.592 3.588L39.978 26.6a2.142 2.142 0 01-.699 3.495 2.142 2.142 0 01-2.336-.466l-10.822-10.82-3.623 3.62a4.285 4.285 0 01-6.057 0l-4.529-4.526zM4.638 35.37H3.209a2.143 2.143 0 000 4.286h21.429a2.143 2.143 0 100-4.286h-1.429v-5a1.428 1.428 0 00-1.428-1.428H6.066a1.428 1.428 0 00-1.428 1.428v5z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1370_92">
          <path
            fill="#fff"
            d="M0 0H40V40H0z"
            transform="translate(.835)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
