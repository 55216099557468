/** @format */

import { createTheme } from "@mui/material/styles";
import axios from "axios";
import { FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import internshipImg from "../../assets/images/Internship.png";
import LegalProfessional from "../../assets/images/LegalProfessional.png";
import HeroContainer from "../../components/HeroContainer";
import { BACKEND_URL } from "../../utils/definitons";

interface FormValues {
  name: string;
  contact: string;
  email: string;
  qualification: string;
  completionyear: string;
  preferredRole: string;
  resume: File | null;
  preferredMonth: number;
}

const inputdata = [
  { id: "name", label: "NAME", placeholder: "Enter Name" },
  {
    id: "contact",
    label: "CONTACT NUMBER",
    placeholder: "Enter Contact Number",
  },
  { id: "email", label: "EMAIL ADDRESS", placeholder: "Enter Email Address" },
  {
    id: "qualification",
    label: "EDUCATIONAL QUALIFICATION",
    placeholder: "Enter Qualification",
  },
  {
    id: "completionyear",
    label: "YEAR OF COMPLETION",
    placeholder: "Enter Year of Completion",
  },
];

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  contact: Yup.string().required("Contact number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  qualification: Yup.string().required("Qualification is required"),
  completionyear: Yup.string().required("Year of completion is required"),
  preferredRole: Yup.string().required("Preferred role is required"),
  resume: Yup.mixed().required("Resume is required"),
  preferredMonth: Yup.number()
    .typeError("Please enter any numbers")
    .required("Preferred Month is required"),
});

const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "14px",
          fontSize: "1.5rem",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "1.5rem",
          "&.Mui-focused": {
            color: "#941C1E",
          },
        },
      },
    },
  },
});

const CareerApplyNow = () => {
  const { field } = useParams();
  const title = field === "internship" ? "INTERNSHIP" : "JOBS";

  const [rendered, setrendered] = useState(false);

  useEffect(() => {
    setrendered(true);
  }, []);

  const formRef = useRef<FormikProps<{}>>();

  const subtitle =
    field === "internship"
      ? "Gain hands-on experience and mentorship in a dynamic legal environment, preparing you for a successful career in law"
      : "Join our team of dedicated legal professionals, where your expertise shapes justice and your career thrives.";
  const img = field === "internship" ? internshipImg : LegalProfessional;

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    console.log("Form submitted:", values);
    setSubmitting(false);
  };

  const isFile = (value: any): value is File => {
    return value instanceof File;
  };

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full">
      <HeroContainer title={title} image={img} subtitle={subtitle} />
      <h1 className="text-[3rem] md:text-4xl lg:text-[3.5rem] font-medium text-center sm:p-[5rem] pt-[5rem] pb-[2rem] text-[#941C1E] ">
        JOIN US NOW
      </h1>
      <div className="px-4 sm:px-6 lg:px-56 py-9 pb-56 2xl:px-[20rem]">
        {field === "internship" ? (
          <iframe
            data-tally-src="https://tally.so/embed/mK07oz?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="414"
            title="Internship Form"
          ></iframe>
        ) : (
          <iframe
            data-tally-src="https://tally.so/embed/wQ0g01?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
            loading="lazy"
            width="100%"
            height="589"
            title="Job form"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default CareerApplyNow;
