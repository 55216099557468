import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        fill={color}
        d="M26.666 6.667A3.322 3.322 0 0130 3.334a3.322 3.322 0 013.333 3.333A3.322 3.322 0 0130 10a3.322 3.322 0 01-3.334-3.333zM33.333 35v-8.333h1.85c1.133 0 1.933-1.117 1.583-2.2l-3.5-10.517a3.35 3.35 0 00-3.166-2.283h-.2a3.334 3.334 0 00-3.167 2.283l-1.433 4.3c1.8 1 3.033 2.884 3.033 5.084v13.333h3.333c.917 0 1.667-.75 1.667-1.667zm-12.5-15.833c1.383 0 2.5-1.117 2.5-2.5 0-1.384-1.117-2.5-2.5-2.5a2.497 2.497 0 00-2.5 2.5c0 1.383 1.117 2.5 2.5 2.5zM9.166 10A3.322 3.322 0 0012.5 6.667a3.322 3.322 0 00-3.334-3.333 3.322 3.322 0 00-3.333 3.333A3.322 3.322 0 009.166 10zM12.5 35V25h.833C14.25 25 15 24.25 15 23.334V15c0-1.833-1.5-3.333-3.334-3.333h-5A3.343 3.343 0 003.333 15v8.334C3.333 24.25 4.083 25 5 25h.833v10c0 .917.75 1.667 1.667 1.667h3.333c.917 0 1.667-.75 1.667-1.667zm4.166-11.666v5c0 .916.75 1.666 1.667 1.666v5c0 .917.75 1.667 1.667 1.667h1.666c.917 0 1.667-.75 1.667-1.667v-5C24.25 30 25 29.25 25 28.334v-5c0-1.367-1.134-2.5-2.5-2.5h-3.334a2.518 2.518 0 00-2.5 2.5z"
      ></path>
    </svg>
  );
}

export default Icon;
