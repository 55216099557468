/** @format */

import React from "react";

function Icon({ color1, color2 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="57"
      height="60"
      fill="none"
      viewBox="0 0 64 64"
    >
      <path
        fill={color1}
        d="M22.14 33.078l8.873 9.149c.568.587 1.405.587 1.973 0l17.147-17.68c1.069-1.107.421-3.213-.987-3.213H33.885L22.14 33.078z"
      ></path>
      <path
        fill={color2}
        d="M30.115 21.334H14.854c-1.409 0-2.056 2.106-.987 3.213l6.416 6.616 9.832-9.83z"
      ></path>
    </svg>
  );
}

export default Icon;
