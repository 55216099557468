/** @format */

import React, { useEffect, useRef } from "react";

interface ServiceDescriptionProps {
  title: string;
  description: React.ReactNode;
  fade: boolean;
}

const ServiceDescription: React.FC<ServiceDescriptionProps> = ({
  title,
  description,
  fade,
}) => {
  return (
    <div
      className={`flex flex-col justify-center items-center mt-8 p-4 transition-opacity duration-300 ${
        fade ? "opacity-100" : "opacity-0"
      }`}
    >
      <h2 className="text-[3rem] font-medium sm:mb-4  tracking-wide">
        OVERVIEW
      </h2>
      <div className="px-7 lg:px-44 py-10 sm:text-3xl text-2xl tracking-wide text-center font-normal leading-normal">
        {description}
      </div>
    </div>
  );
};

export default ServiceDescription;
