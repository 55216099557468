/** @format */

import React, { useState } from "react";
import TeamBanner from "../../assets/images/Teams/TeamBanner.png";
import HeroContainer from "../../components/HeroContainer";
import ProfileCard from "../../components/ProfileCard/ProfileCard";
import { profileData } from "../../constants/ProfileData";
import ProfileDetailsCard from "../../components/ProfileDetailsCard/ProfileDetailsCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function Index() {
   const [selectedIndex, setselectedIndex] = useState<number | null>(null);
   console.log(selectedIndex, "selectedIndex");
   return (
      <div id="team">
         {selectedIndex === null && (
            <HeroContainer
               title={"OUR TEAM"}
               image={"https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/zujuldaesgswevydxwt0"}
               subtitle="Meet the dedicated professionals who bring expertise, experience, and passion to every client we serve."
            />
         )}
         <div className="flex flex-wrap py-[5rem] max-lg:flex-col md:gap-[4rem] gap-[1rem] items-center justify-between xl:justify-around w-[90%] lg:w-[80%] mx-auto">
            {selectedIndex == null && (
               <>
                  {profileData.map((item, index: number) => (
                     <div key={index}>
                        <ProfileCard
                           index={index}
                           image={item.image}
                           designation={item.designation}
                           name={item.name}
                           phone={item.phone}
                           role={item.role}
                           mail={item.mail}
                           onClick={() => setselectedIndex(index)}
                        />
                     </div>
                  ))}
               </>
            )}
            {selectedIndex !== null && (
               <div className="mt-[4rem]">
                  <div
                     onClick={() => setselectedIndex(null)}
                     className="text-[2rem] mb-5 cursor-pointer">
                     <span>
                        <ArrowBackIcon
                           fontSize="large"
                           className="mr-[5px]"
                        />
                     </span>
                     Go back
                  </div>
                  <ProfileDetailsCard
                     designation={profileData[selectedIndex].designation}
                     image={profileData[selectedIndex].image}
                     mail={profileData[selectedIndex].mail}
                     name={profileData[selectedIndex].name}
                     overview={profileData[selectedIndex].overview}
                     phone={profileData[selectedIndex].phone}
                     role={profileData[selectedIndex].role}
                  />
               </div>
            )}
         </div>
      </div>
   );
}
