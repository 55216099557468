/** @format */

import Container from "../../components/Container/Container";
import RenderCards from "../../components/RenderCards";
import ValueCard from "../../components/ValueCard/ValueCard";
import { useIsSmallerDevice } from "../../hooks/useIsSmallerDevice";

export default function Index() {
   const valueData = [
      [
         {
            id: 1,
            icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/xvtgiip5xrbhn4xea7rc",
            title: "OUR VALUES",
            subtitle: "At Leglsols, we hold steadfast to a set of core values that underpin every aspect of our practice",
            page: "/ourvalues",
         },
         {
            id: 1,
            icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/iu661dtszlhfuaxntr0n",
            title: "OUR INSIGHTS",
            subtitle: "Beyond legalese, lies understanding. Explore our insights to demystify legal concepts and make the law work for you",
            page: "/blogs",
         },
      ],
      [
         {
            id: 1,
            icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/ox0y0qzav2ntequf29b1",
            title: "OUR TEAM",
            subtitle: "A multifaceted approach that blends legal expertise with sound management principles and a deep understanding of technology",
            page: "/teams",
         },
         {
            id: 1,
            icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/ghxwkilqb5fspfl1dyje",
            title: "CAREERS",
            subtitle: "We believe in fostering exceptional talent and providing a platform for continuous growth",
            page: "/careers",
         },
      ],
   ];

   const isSmallerDevice = useIsSmallerDevice();

   const isLastIndex = (index: number) => {
      return index === valueData.length - 1;
   };

   return (
      <Container>
         <RenderCards
            data={valueData}
            renderChildren={(card: any) => {
               return (
                  <ValueCard
                     key={card.id}
                     icon={card.icon}
                     title={card.title}
                     subtitle={card.subtitle}
                     onClick={() => (window.location.href = card.page)}
                  />
               );
            }}
         />
      </Container>
   );
}
