import React from "react";

function Icon() {
  const innerwidth = window.innerWidth;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={innerwidth > 1030 ? 30 : 20}
      height={innerwidth > 1030 ? 30 : 20}
      fill="none"
      viewBox="0 0 30 31"
    >
      <g clipPath="url(#clip0_1205_1397)">
        <path
          fill="#0077B5"
          d="M25.5.938h-21a4.5 4.5 0 00-4.5 4.5v21a4.5 4.5 0 004.5 4.5h21a4.5 4.5 0 004.5-4.5v-21a4.5 4.5 0 00-4.5-4.5z"
        ></path>
        <path
          fill="#FDFEFE"
          d="M8.32 11.191a2.168 2.168 0 100-4.336 2.168 2.168 0 000 4.336zM14.297 12.305v11.601-11.601zm-5.977 0v11.601-11.601z"
        ></path>
        <path
          stroke="#FDFEFE"
          strokeWidth="3.867"
          d="M14.297 12.305v11.601M8.32 12.305v11.601"
        ></path>
        <path
          fill="#FDFEFE"
          d="M16.172 17.46c0-1.17.762-2.343 2.11-2.343 1.405 0 1.933 1.055 1.933 2.637v6.152h3.867v-6.62c0-3.575-1.875-5.216-4.453-5.216-1.992 0-2.988 1.114-3.457 1.875"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1205_1397">
          <path
            fill="#fff"
            d="M0 0H30V30H0z"
            transform="translate(0 .938)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
