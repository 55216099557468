/** @format */

import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import DisclamierModal from "../../components/DisclamierModal/DisclamierModal";
import RenderCards from "../../components/RenderCards";
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import { servicesData } from "../../constants/ServicesData";
import { SHEET_URL } from "../../definitons";
import { ContactProps } from "../../interfaces/contact";
import { getCurrentDateAndTime } from "../../utils/dateFunction";
import Clients from "../Clients";
import Hero from "../Hero";
import OurLocations from "../Location";
import Values from "../Values";

export default function Index() {
  const navigate = useNavigate();
  const [showContactInputModal, setshowContactInputModal] = useState(false);

  const onSubmit = (values: ContactProps) => {
    const payload = {
      Name: values.name,
      "Job title": values.job_title,
      "Company name": values.company_name,
      Email: values.email,
      "Created on": getCurrentDateAndTime(),
      Message: values.message,
    };

    fetch(`${SHEET_URL}/tabs/Contacts`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    }).then((response) => {
      setshowContactInputModal(false);
      toast.success(
        "Thank you for contacting us. We will get back to you shortly!"
      );
    });
    console.log(values);
  };

  return (
    <div className="">
      <DisclamierModal />
      <Hero />
      <Values />
      <Clients />
      <div className="flex items-center justify-center mt-20 lg:mt-16">
        <h1 className="text-[#941C1E] text-[3.5rem] font-medium pt-6 -mb-4 lg:p-6 uppercase tracking-wider ">
          Services
        </h1>
      </div>
      <RenderCards
        data={[servicesData[0]]}
        renderChildren={(card: any) => {
          return (
            <ServiceCard
              key={card.id}
              icon={card.icon}
              title={card.title}
              subtitle={card.subtitle}
            />
          );
        }}
      />

      <div className="flex items-center justify-center my-3">
        <Button
          title="See more"
          onClick={() => (window.location.href = "/services")}
        />
      </div>

      <OurLocations />

      <div className=" hidden lg:flex items-center justify-center my-6 lg:my-4">
        <Button title="Contact us" onClick={() => navigate(`/contacts`)} />
      </div>
    </div>
  );
}
