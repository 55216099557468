/** @format */

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BlogCard from "../../components/BlogCard/BlogCard";
import HeroContainer from "../../components/HeroContainer";
import { AUTHORIZATION_KEY, BACKEND_URL } from "../../utils/definitons";

interface BlogProps {
   blog_id: string;
   blog_name: string;
   blog_details: string;
   created_at: string;
   cloudflare_image_url: string;
   html_content: string;
}

export default function Index() {
   const navigate = useNavigate();

   const fetchAllBlogs = async () => {
      return axios
         .get(`${BACKEND_URL}/leglsols-fetch-blogs`, {
            headers: {
               Authorization: AUTHORIZATION_KEY,
            },
         })
         .then((res) => res.data);
   };

   const { data: allBlogs, isLoading } = useQuery({
      queryKey: ["blogs"],
      queryFn: fetchAllBlogs,
   });

   return (
      <div id="blogs">
         <HeroContainer
            title="Blogs"
            image={"https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/blogsBannerImage"}
            subtitle="Insights and updates on the latest legal trends and developments from our expert team."
         />
         <div className="py-[2rem] md:py-[5rem]">
            <div className="mt-[4rem] md:ml-[6rem] max-md:flex max-md:justify-center">
               <button
                  onClick={() => (window.location.href = "/")}
                  className="text-[2rem] cursor-pointer flex items-center"
                  style={{
                     background: "none",
                     border: "none",
                     padding: 0,
                     color: "inherit",
                  }}>
                  <ArrowBackIcon
                     fontSize="large"
                     className="mr-[5px]"
                  />
                  Go back
               </button>
            </div>
            <div className="pt-[4rem] flex flex-wrap justify-center items-center gap-[4rem]">
               {isLoading && (
                  <>
                     <Skeleton
                        variant="rectangular"
                        width={400}
                        animation="wave"
                        height={260}
                        className="w-[27rem] md:w-[40rem] md:h-[55rem] h-[50rem]"
                     />
                     <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={400}
                        height={260}
                        className="w-[27rem] md:w-[40rem] md:h-[55rem] h-[50rem]"
                     />

                     <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width={400}
                        height={260}
                        className="w-[27rem] md:w-[40rem] md:h-[55rem] h-[50rem]"
                     />
                  </>
               )}

               {allBlogs?.map((item: BlogProps, index: number) => (
                  <BlogCard
                     key={index}
                     image={item.cloudflare_image_url}
                     title={item.blog_name}
                     details={item.blog_details}
                     date={item.created_at}
                     onClick={() => navigate(`/blogs/${item.blog_id}`)}
                  />
               ))}
            </div>
         </div>
      </div>
   );
}
