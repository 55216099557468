/** @format */

import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "../DisclamierModal/Checkbox";
import Modal from "../Modal";

export default function BasicModal() {
   const [open, setOpen] = React.useState(false);
   const [acceptedDisclaimer, setacceptedDisclaimer] = useState(false);

   const onChangeCheckBox = () => {
      setacceptedDisclaimer(!acceptedDisclaimer);
      localStorage.setItem("acceptedDisclaimer", JSON.stringify(!acceptedDisclaimer));
   };

   useEffect(() => {
      setacceptedDisclaimer(JSON.parse(localStorage.getItem("acceptedDisclaimer") as string));
   }, []);

   useEffect(() => {
      const value = JSON.parse(localStorage.getItem("acceptedDisclaimer") as string);
      if (value === undefined || !value) {
         setOpen(true);
      }
   }, []);

   return (
      <Modal
         visible={open}
         onClose={() => {}}>
         <h2 className="text-[3.5rem] font-semibold mb-[1.5rem]">Disclaimer</h2>
         <p className="text-[1.4rem]">
            This website has been created solely for the purpose of disseminating basic information about ASN Atthik Legal Solutions, information that is otherwise available on the internet, various public
            platforms, and social media. Great care has been taken to ensure that the information provided here is accurate and up-to-date. However, ASN Atthik Legal Solutions is not accountable for any reliance
            placed by readers on such information and shall not be held liable for any loss or damage caused by inaccuracies or omissions in the information, or its interpretation thereof. Readers are
            advised to confirm the accuracy of the information from independent and expert sources.
         </p>
         <p className="text-[1.4rem] my-[1.5rem]">
            This website isn't trying to advertise or get clients, and it's not trying to start any lawyer-client relationship. The links here are just to help you find basic info about ASN Atthik Legal Solutions and to share what they're up to with thought leadership stuff. Don't think of the content here or on those links as legal advice or a legal reference. It's a good idea not to act
            on anything you read here or on the links, and it's better to talk to legal experts in your area to get more info and figure out what it means for you.
         </p>
         <p className="text-[1.4rem]">
            ASN Atthik Legal Solutions advises against the use of the communication platform provided on this website for exchange of any confidential, business or politically sensitive information. User is
            requested to use his or her judgment and exchange of any such information shall be solely at the user's risk. ASN Atthik Legal Solutions uses cookies on its website to enhance usability. This
            practice assists us in delivering a satisfactory user experience and contributes to the ongoing improvement of our website. By continuing to utilize our website without modifying your
            privacy settings, you consent to the use of our cookies.
         </p>
         <div className="mt-[1rem]">
            <div className="flex items-center ml-[-1rem]">
               <Checkbox
                  checked={acceptedDisclaimer}
                  onChange={onChangeCheckBox}
               />
               <p className="text-[1.5rem]">I accept the above</p>
            </div>
            <button
               onClick={() => acceptedDisclaimer && setOpen(false)}
               style={{ border: "0.5px solid #000", opacity: acceptedDisclaimer ? 1 : 0.5 }}
               className="mt-[1rem] text-[1.5rem] text-[#000] font-semibold py-2 px-4 ">
               Proceed to Website
            </button>
         </div>
      </Modal>
   );
}
