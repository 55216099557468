/** @format */

import React from "react";
import Modal from "@mui/material/Modal";

interface Props {
   visible: boolean;
   children: React.ReactNode;
   onClose: () => void;
}

export default function Index({ visible, children, onClose }: Props) {
   return (
      <Modal
         open={visible}
         onClose={onClose}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description">
         <div className="absolute bg-white top-1/2 p-[2rem] rounded-[1rem] overflow-scroll lg:overflow-auto lg:w-auto w-[90%] outline-none lg:h-auto h-[90%] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {children}
         </div>
      </Modal>
   );
}
