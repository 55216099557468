/** @format */

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Services from "./Pages/service";
import TermsAndConditions from "./Pages/TermsAndConditions";
import GuidingPrinciples from "./Pages/GuidingPrinciples";
import Careers from "./Pages/Careers";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import HeaderBar from "./components/HeaderBar/HeaderBar";
import Footer from "./components/Footer";
import OurTeam from "./Pages/Teams";
import StalinProfile from "./Pages/ProfileDetails/StalinProfile";
import toast, { Toaster } from "react-hot-toast";
import Blog from "./Pages/Blogs/";
import BlogProperty from "./Pages/Blogs/Property";
import BlogCopyrights from "./Pages/Blogs/Copyrights";
import DetailedBlog from "./Pages/DetailedBlog";
import CareerApplyNow from "./components/CareerApplyNow";
import Contact from "./Pages/Contact";

function App() {
   useEffect(() => {
      AOS.init({
         disable: true,
         duration: 2000,
         once: true,
      });
   }, []);

   useEffect(() => {
      const widgetScriptSrc = "https://tally.so/widgets/embed.js";

      const load = () => {
         // Load Tally embeds
         //@ts-ignore
         if (typeof Tally !== "undefined") {
            //@ts-ignore
            Tally.loadEmbeds();
            return;
         }

         // Fallback if window.Tally is not available
         //@ts-ignore
         document.querySelectorAll("iframe[data-tally-src]:not([src])").forEach((iframeEl) => {
            //@ts-ignore
            iframeEl.src = iframeEl.dataset.tallySrc;
         });
      };

      // If Tally is already loaded, load the embeds
      //@ts-ignore
      if (typeof Tally !== "undefined") {
         load();
         return;
      }

      // If the Tally widget script is not loaded yet, load it
      if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
         const script = document.createElement("script");
         script.src = widgetScriptSrc;
         script.onload = load;
         script.onerror = load;
         document.body.appendChild(script);
         return;
      }
   }, []);

   return (
      <BrowserRouter>
         <div>
            <HeaderBar />
            <Toaster containerStyle={{ fontSize: "1.3rem" }} />
            <Routes>
               <Route
                  path="/"
                  element={<Home />}
               />
               <Route
                  path="/careers"
                  element={<Careers />}
               />
               <Route
                  path="/teams"
                  element={<OurTeam />}
               />
               <Route
                  path="/teams/stalin"
                  element={<StalinProfile />}
               />
               <Route
                  path="/teams/sesubalanraja"
                  element={<StalinProfile />}
               />
               <Route
                  path="/ourvalues"
                  element={<GuidingPrinciples />}
               />
               <Route
                  path="/services"
                  element={<Services />}
               />
               <Route
                  path="/services/:id"
                  element={<Services />}
               />
               <Route
                  path="/termsandconditions"
                  element={<TermsAndConditions />}
               />
               <Route
                  path="careers/apply-now/:field"
                  element={<CareerApplyNow />}
               />

               <Route
                  path="/blogs"
                  element={<Blog />}
               />
               <Route
                  path="/blogs/:id"
                  element={<DetailedBlog />}
               />
               <Route
                  path="/contacts"
                  element={<Contact />}
               />
            </Routes>
            <Footer />
         </div>
      </BrowserRouter>
   );
}

export default App;
