/** @format */

import React from "react";
import { profileData } from "../../constants/ProfileData";
import ProfileDetailsCard from "../../components/ProfileDetailsCard/ProfileDetailsCard";

export default function index() {
   const selectedProfileData = profileData[0];

   return (
      <div>
         <ProfileDetailsCard
            image={selectedProfileData.image}
            name={selectedProfileData.name}
            designation={selectedProfileData.designation}
            phone={selectedProfileData.phone}
            mail={selectedProfileData.mail}
            overview={selectedProfileData.overview}
            role={selectedProfileData.role}
         />
      </div>
   );
}
