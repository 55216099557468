/** @format */

import React from "react";
import { useIsSmallerDevice } from "../../hooks/useIsSmallerDevice";

interface Props {
  data: any[];
  renderChildren: any;
}

export default function Index({ data, renderChildren }: Props) {
  const isSmallerDevice = useIsSmallerDevice();

  const isLastIndex = (index: number) => {
    return index === data.length - 1;
  };

  return (
    <div className="mx-auto my-4  xl:w-[80%] max-md:max-w-[50rem] w-[100%] ">
      {data.map((item, mainIndex) => (
        <>
          <div className="flex  items-center md:items-start  flex-col md:flex-row justify-center max-md:mt-[4rem] xl:gap-[10rem] lg:gap-[5rem] h-auto w-[100%]">
            {item.map((card: any, index: number) => (
              <>
                {renderChildren(card)}
                {index === 0 && !isSmallerDevice && (
                  <div className="bg-[#D9D9D9] h-[55rem] w-[1px]"></div>
                )}
              </>
            ))}
          </div>
          {!isLastIndex(mainIndex) && !isSmallerDevice && (
            <div className="bg-[#D9D9D9] w-full my-[2rem] h-[1px]"></div>
          )}
        </>
      ))}
    </div>
  );
}
