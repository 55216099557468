/** @format */

import React from "react";
import { ProfileCardProps } from "../ProfileCard/ProfileCard";
import PhoneIcon from "../../assets/icons/PhoneIcon";
import MailIcon from "../../assets/icons/MailIcon";

interface ProfileDetailsCardProps extends ProfileCardProps {
   overview: {
      about: string;
      expertise: string;
      professional_experience: string;
      education: string;
      professional_affiliations: string;
   };
}

export default function ProfileDetailsCard({ overview, designation, name, image, phone, mail, role }: ProfileDetailsCardProps) {
   return (
      <div className="w-full flex flex-col lg:flex-row h-full py-10 sm:py-14 px-4 sm:px-0">
         <div className="w-full sm:w-1/3 md:w-1/2 lg:w-1/3 rounded-[1rem] flex-shrink-0 mb-6 sm:mb-0 md:mb-auto md:flex md:justify-center lg:justify-start">
            <img
               src={image}
               className="w-full sm:w-auto rounded-[1rem] object-cover"
               alt="profile"
            />
         </div>
         <div className="flex flex-col gap-[2rem] w-full sm:w-2/3 md:w-1/2 lg:w-2/3 sm:px-20 md:px-8 justify-center mx-auto lg:mx-0">
            <div className="text-center">
               <p className="text-3xl sm:text-[2rem] font-medium">{designation}</p>
               <div className="mx-auto my-2 w-[6rem] sm:w-[10rem]">
                  <hr className="bg-[#941C1E] w-full h-[0.2rem]" />
               </div>
            </div>
            <p className="text-2xl sm:text-[1.6rem] font-medium text-center uppercase">{role}</p>
            <p className="text-4xl sm:text-[2.8rem] md:text-[3rem] font-bold tracking-wide text-center text-[#000000]">{name}</p>
            <div className="flex flex-col sm:flex-row items-center justify-center gap-[1.5rem] sm:gap-[2.5rem]">
               <a
                  href={`tel:${phone}`}
                  className="flex items-center gap-2">
                  <PhoneIcon />
                  <p className="text-2xl sm:text-[1.5rem] md:text-[1.4rem] font-medium underline">{phone}</p>
               </a>
               <a
                  href={`mailto:${mail}`}
                  className="flex items-center gap-2">
                  <MailIcon />
                  <p className="text-2xl sm:text-[1.5rem] md:text-[1.4rem] font-medium underline">{mail}</p>
               </a>
            </div>
            <div>
               <p className="text-[3rem] sm:text-[3.5rem] md:text-[3rem] text-center font-medium text-[#000000] tracking-wide">OVERVIEW</p>
               <br />
               <div className="text-center sm:text-center font-medium text-[1.5rem] sm:text-[1.8rem] md:text-[1.6rem]">
                  <p className="text-[#941C1E] text-left">About</p>
                  <p className="text-left">{overview.about}</p>
                  <br />
                  <p className="text-[#941C1E] text-left">Expertise</p>
                  <p className="text-left">{overview.expertise}</p>
                  <br />
                  <p className="text-[#941C1E] text-left">Professional Experience</p>
                  <p className="text-left">{overview.professional_experience}</p>
                  <br />
                  <p className="text-[#941C1E] text-left">Education</p>
                  <p className="text-left">{overview.education}</p>
                  <br />
                  {overview.professional_affiliations && (
                     <>
                        <p className="text-[#941C1E] text-left">Professional Affiliations</p>
                        <p className="text-left">{overview.professional_affiliations}</p>
                     </>
                  )}
               </div>
            </div>
         </div>
      </div>
   );
}
