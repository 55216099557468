/** @format */

import React, { useState } from "react";

interface ServicesPageCardProps {
  id: string;
  icon: any;
  title: string;
  arrow: any;
  onClick: () => void;
  selectedCardId: string | null;
  setSelectedCardId: (id: string) => void;
  IsCardSelected: boolean;
}

export default function ServicePageCard({
  id,
  icon,
  title,
  arrow,
  onClick,
  selectedCardId,
  setSelectedCardId,
  IsCardSelected,
}: ServicesPageCardProps) {
  const [isHovered, setIsHovered] = useState(false);

  console.log(IsCardSelected, "selectedCard");

  const handleCardClick = (id: string) => {
    onClick();
    setSelectedCardId(id);
  };

  return (
    <div
      className={`${
        IsCardSelected && "border-[2px] !border-[#941C1E]"
      } flex flex-col  items-center font-normal text-center text-[1rem] justify-between text-[#941C1E] underline lg:no-underline lg:text-[#000000] sm:w-[15rem] w-[100%] h-[16rem] lg:w-1/4 border-[2px] rounded-2xl sm:m-4 sm:px-1 border-black py-4  hover:border-[#941C1E] hover:border-3px transition duration-2s cursor-pointer`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => handleCardClick(id)}
    >
      <div
        className={`transition-transform duration-300 scale-50 lg:scale-95 ${
          isHovered || IsCardSelected ? "lg:scale-125" : ""
        }`}
      >
        {React.cloneElement(icon, {
          color: isHovered || IsCardSelected ? "#941C1E" : "#000",
        })}
      </div>
      <p
        className={`text-[1.3rem] lg:text-xl font-bold  ${
          isHovered || IsCardSelected ? "underline text-red-700" : ""
        }`}
      >
        {title}
      </p>
      <div
        className={`transition-transform duration-300 scale-50 lg:scale-95 ${
          isHovered || IsCardSelected ? "lg:scale-125" : "scale-100"
        }`}
      >
        {React.cloneElement(arrow, {
          color:
            isHovered || IsCardSelected || window.innerWidth < 640
              ? "#941C1E"
              : "#000",
        })}
      </div>
    </div>
  );
}
