/** @format */

export const profileData = [
   {
      image: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Teams/neuttttudyjtpg0edbpm",
      designation: "Head of Legal",
      role: "Litigation & Real estate",
      name: "SESUBALANRAJA P",
      phone: "+91 8942424576",
      mail: "sesubalanraja@asnatthiklegal.com",
      overview: {
         about: "Sesubalan Raja P is the Head of Legal (Civil & Litigation) at ASN Atthik Legal Solutions LLP, with an illustrious career spanning over 30 years. As a seasoned Civil Advocate, he has gained recognition for his unparalleled expertise in civil litigation. His unwavering commitment to justice, coupled with a robust track record of successful cases, has solidified his reputation as one of the leading legal experts in the field.",
         expertise:
            "Sesubalan Raja P is renowned for his adept handling of complex civil cases, including: Property Disputes Contract Law, Family Law, Torts, Corporate Litigation. His profound knowledge of both procedural and substantive law, combined with strategic acumen, has consistently led to favorable verdicts for his clients. He is distinguished by his ability to deconstruct intricate legal issues and present compelling arguments in court.",
         professional_experience:
            "With a career marked by significant achievements, Sesubalan has represented a wide range of clients, delivering favorable outcomes in property disputes, contract law, family law, and consumer protection cases. He commenced his legal journey under the mentorship of Advocate Mahadevan, where he honed his skills in high-stakes litigation. His participation in landmark cases has contributed to setting crucial legal precedents.",
         education: "LL.B., Central Law College, Salem, graduating with honors.",
         professional_affiliations: "",
      },
   },
   {
      image: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Teams/mgiwfjyo0wz0rhv4ntpm",
      designation: "Head of Legal",
      role: "Banking & Finance",
      name: "ANTONY L.X. SELVAM",
      phone: "+91 8942424576",
      mail: "antony@asnatthiklegal.com",
      overview: {
         about: "Mr.Antony L.X.Selvam, a partner at Global Legalsols LLP, operates from the firm's Chennai office. With over 18 years of experience in the legal arena, he is  a distinguished advocate specializing in Debt Recovery Tribunal(DRT) matters.Antony's comprehensive knowledge and expertise in DRT cases, coupled with his strong educational background, make him a trusted authority in the field.",
         expertise:
            "Antony has dedicated a significant portion of his career to representing clients in Debt Recovery Tribunal (DRT) cases. His deep understanding of the legal intricacies involved in debt recovery matters, coupled with his strategic approach, has resulted in numerous successful outcomes for his clients.",
         professional_experience:
            "Antony currently serves as the Secretary for the Debt Recovery Tribunal (DRT) and Debt Recovery Appellate Tribunal (DRAT) Bar Association. In this role, he actively contributes to the advancement of the legal profession, facilitates knowledge sharing among peers, and advocates for the rights of association members.",
         education:
            "Antony holds a Bachelor of Commerce (B. Com) from St. Joseph College, Trichy, followed by a Master's degree from Annamalai University. He pursued his passion for law and earned his Bachelor of Law (B.L) degree from Salem Law College in 2005.",
         professional_affiliations:
            "Antony is a member of the Debt Recovery Tribunal (DRT) and Debt Recovery Appellate Tribunal (DRAT) Bar Association. He is also a member of the Indian Bar Association and the Indian Law Association.",
      },
   },
   {
      image: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Teams/yuolqbnyofefvzkqnvdh",
      designation: "Head of Legal",
      role: "IP & Family matters",
      name: "DEVI N",
      phone: "+91 8942424576",
      mail: "devi@asnatthiklegal.com",
      overview: {
         about: "Mrs. Devi N, Head of Legal (IP & Family Matters) at ASN Atthik Legal Solutions LLP, brings over 25 years of extensive legal experience. She has established herself as a leading authority in intellectual property law and family matters. With over 500 appearances in various courts, including the High Court of Madras, and the successful filing of more than 800 trademark applications, her expertise is both broad and deep. Her work in securing numerous copyright and design registrations, along with her strong international legal connections, highlights her comprehensive understanding of the global legal landscape.",
         expertise:
            "Devi N's areas of specialization include: Intellectual Property Law (trademark, copyright, design applications), Matrimonial Law, Family Court Matters, Property Law, Documentation and Arbitration. Her strategic approach and comprehensive legal knowledge have enabled her to effectively advocate for her clients, ensuring the protection of their intellectual property rights and navigating complex family law cases. Member, Intellectual Property Attorneys of South India",
         professional_experience:
            "Standing Counsel, Salem City Municipal Corporation, Panel Advocate, Canara Bank, Member, Internal Committees under PoSH for companies, Member, Worldwide Independent Lawyers League (WILL) - Austria, Member, Intellectual Property Attorneys of South India",
         education: "LL.B., Madras Law College, Represented India in the International Moot Court Competition, Vienna, Austria",
         professional_affiliations: "",
      },
   },
];
