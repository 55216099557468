import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <g clipPath="url(#clip0_888_564)">
        <path
          fill={color}
          d="M38.166 1.25a1.25 1.25 0 00-1.81-1.118l-20 10a1.25 1.25 0 00-.69 1.118v7.85L1.521 23.815A1.25 1.25 0 00.666 25v13.75A1.25 1.25 0 001.916 40h22.5a1.25 1.25 0 001.25-1.25V35h2.5v3.75a1.25 1.25 0 001.25 1.25h7.5a1.25 1.25 0 001.25-1.25V1.25zM5.666 27.5h2.5V30h-2.5v-2.5zm5 0h2.5V30h-2.5v-2.5zm-2.5 5V35h-2.5v-2.5h2.5zm2.5 0h2.5V35h-2.5v-2.5zm22.5-25V10h-2.5V7.5h2.5zm-12.5 5h2.5V15h-2.5v-2.5zm2.5 5V20h-2.5v-2.5h2.5zm-2.5 5h2.5V25h-2.5v-2.5zm5 0h2.5V25h-2.5v-2.5zm-2.5 5V30h-2.5v-2.5h2.5zm2.5 0h2.5V30h-2.5v-2.5zm7.5-5V25h-2.5v-2.5h2.5zm-2.5 5h2.5V30h-2.5v-2.5zm-5-10h2.5V20h-2.5v-2.5zm7.5 0V20h-2.5v-2.5h2.5zm-5-5V15h-2.5v-2.5h2.5zm2.5 0h2.5V15h-2.5v-2.5z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_888_564">
          <path
            fill="#fff"
            d="M0 0H40V40H0z"
            transform="translate(.666)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
