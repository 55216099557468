/** @format */

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FooterLogo from "../../assets/images/FooterLogo.png";
import LinkedIn from "../../assets/icons/LinkedIn.jsx";
import Insta from "../../assets/icons/InstaIcon.jsx";
import Twitter from "../../assets/icons/Twitter.jsx";
import Logo from "../../assets/images/LegsolLogo.png";

export default function Footer() {
  return (
    <div
      id="footer"
      className="bg-black text-white py-8 md:py-12 lg:py-[7rem] px-4 md:px-10 xl:px-[10rem]"
    >
      <div className="flex flex-col md:flex-row flex-wrap justify-between text-sm md:text-base xl:gap-5 gap-2 lg:text-lg xl:text-xl">
        {/* Logo and Follow Us Section */}
        <div className="flex flex-col items-center md:items-start mb-6 md:mb-8 lg:mb-0 gap-4 md:gap-6">
          <div className="bg-white max-md:w-[90%] max-md:max-w-[40rem] max-md:h-[12rem] max-md:mx-auto  flex justify-center items-center p-3 rounded-xl lg:rounded-full md:rounded-full">
            <img
              src={Logo}
              alt="ASN ATTHIK LEGAL SOLUTIONS Logo "
              className="w-[7rem] h-[7rem] scale-150"
            />
          </div>
          <div className="flex flex-col md:flex-row gap-2 items-center mt-[2rem]">
            <div className="flex items-center gap-5">
              <p className="text-center text-medium pt-4 md:pt-6 lg:pt-0 text-3xl font-medium xl:text-[2.5rem] md:text-[2rem] lg:text-left mb-2 md:mb-6 lg:mb-0">
                Follow Us
              </p>
              <a
                href="https://x.com/ASNAtthikLegal"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <Twitter />
              </a>
              <a
                href="https://www.linkedin.com/company/asn-atthik"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <LinkedIn />
              </a>
              <a
                href="https://www.instagram.com/asnatthiklegal"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white"
              >
                <Insta />
              </a>
            </div>
          </div>
        </div>

        {/* Address Section */}
        <div className="flex flex-col items-center md:items-start mb-6 md:mb-8 lg:mb-0 gap-6 lg:gap-2 md:gap-8">
          <p className="text-center md:text-left text-4xl lg:text-4xl md:text-2xl font-medium">
            Address
          </p>
          <p className="text-center md:text-left text-xl md:text-base lg:text-2xl tracking-wide ">
            100/1E, Dr Radhakrishnan Road,
            <br />
            Mylapore, Chennai 600004
            <br />
            India
          </p>
        </div>

        {/* Office Hours Section */}
        <div className="flex flex-col items-center md:items-start mb-6 md:mb-8 lg:mb-0 gap-6 lg:gap-2 md:gap-8">
          <p className="text-center md:text-left text-4xl lg:text-4xl md:text-2xl font-medium">
            Office Hours
          </p>
          <p className="text-center md:text-left text-xl md:text-base lg:text-2xl">
            Monday to Saturday
            <br />
            9:00 am to 9:00 pm IST
          </p>
          <p className="text-center md:text-left text-xl md:text-base lg:text-2xl">
            Sunday
            <br />
            9:00 am to 12 noon IST
          </p>
        </div>

        {/* Contact Section */}
        <div className="flex flex-col items-center md:items-start mb-6 md:mb-8 lg:mb-0 gap-6 lg:gap-2 md:gap-8 tracking-wide decoration-slate-500">
          <p className="text-center md:text-left text-4xl lg:text-4xl md:text-2xl font-medium">
            Contact
          </p>
          <a
            href="tel:+918939891916"
            className="text-center md:text-left text-lg md:text-base lg:text-2xl cursor-pointer underline decoration-slate-500 font-light"
          >
            +91 8939891916
          </a>
          <a
            href="mailto:admin@asnatthiklegal.com"
            className="text-center md:text-left text-xl md:text-base lg:text-2xl underline font-light"
          >
            admin@asnatthiklegal.com
          </a>
        </div>

        {/* Legal Policies Section */}
        <div className="flex flex-col items-center md:items-start gap-6 lg:gap-2 md:gap-8">
          <p className="text-center md:text-left text-4xl lg:text-4xl md:text-2xl font-medium">
            Legal Policies
          </p>
          <a
            href="termsandconditions"
            target="_blank"
            rel="noopener noreferrer"
            className="text-center decoration-slate-500 md:text-left text-xl md:text-base lg:text-2xl underline font-light"
          >
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  );
}
