/** @format */

import Container from "../../components/Container/Container";
import LocationCard from "../../components/LocationCard/LocationCard";

export default function Index() {
  const imagesData = [
    {
      id: 1,
      image:
        "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Locations/gpx07m5g9vioiz1wfulg",
      locationName: "INDIA",
    },
    {
      id: 2,
      image:
        "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Locations/znqclczopxqyyyckodqo",
      locationName: "DUBAI",
    },
    {
      id: 3,
      image:
        "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Locations/eskjzoatkrqsw0qmmbyl",
      locationName: "SINGAPORE",
    },
    {
      id: 4,
      image:
        "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Locations/fnnhwqej1kmxpu4j5spt",
      locationName: "MALAYSIA",
    },
  ];

  return (
    <Container>
      <h2 className="text-[3.5rem] lg:m-[1rem] md:m-[3rem] mt-[4rem] text-[#941C1E] text-center lg:mb-[7rem] font-medium">
        OUR LOCATIONS
      </h2>
      <div className="sm:flex sm:flex-wrap sm:justify-center grid grid-cols-2 md:gap-[1rem] gap-3 py-[5rem] lg:py-0">
        {imagesData.map((item) => (
          <LocationCard
            key={item.id}
            image={item.image}
            locationName={item.locationName}
          />
        ))}
      </div>
    </Container>
  );
}
