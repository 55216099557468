import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
       fill={color}
        d="M4 6a2 2 0 114 0h26a2 2 0 010 4H8v25a1 1 0 01-1 1H5a1 1 0 01-1-1V6zm6 7a1 1 0 011-1h22a1 1 0 011 1v14a5 5 0 01-5 5H15a5 5 0 01-5-5V13zm6 14a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-5.1a2 2 0 00-.672-1.496l-4.664-4.144a1 1 0 00-1.328 0l-4.664 4.14A2 2 0 0016 21.9V27z"
      ></path>
    </svg>
  );
}

export default Icon;
