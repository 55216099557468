/** @format */

import React from "react";

interface ContainerProps {
  children: any;
}

export default function Container({ children }: ContainerProps) {
  return (
    <div className="  py-[1rem] px-[2rem] md:py-[5rem] md:px-[13rem] max-w-[100%] overflow-hidden box-border">
      {children}
    </div>
  );
}
