import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
       fill={color}
        d="M20 5c-2.116 0-4 1.333-4.7 3.333H5v3.334h3.25L3.334 23.333c-.784 3.334 1.666 5 5.833 5s6.767-1.666 5.833-5l-4.916-11.666h5.2a5.29 5.29 0 003.05 3.05v18.616h-15v3.334h33.333v-3.334h-15V14.7a5.145 5.145 0 003.033-3.033h5.217L25 23.333c-.783 3.334 1.667 5 5.834 5 4.166 0 6.766-1.666 5.833-5L31.75 11.667H35V8.333H24.717C24 6.333 22.117 5 20 5zm0 3.333a1.667 1.667 0 110 3.334 1.667 1.667 0 010-3.334zm-10.833 8.75l2.5 6.25h-5l2.5-6.25zm21.667 0l2.5 6.25h-5l2.5-6.25z"
      ></path>
    </svg>
  );
}

export default Icon;
