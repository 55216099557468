/** @format */

import * as React from "react";
import { pink } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface Props {
   checked: boolean;
   onChange: () => void;
}

export default function ColorCheckboxes({ checked, onChange }: Props) {
   return (
      <Checkbox
         {...label}
         checked={checked}
         onChange={onChange}
         sx={{
            "& .MuiSvgIcon-root": { fontSize: 23 },
            color: pink[800],
            "&.Mui-checked": {
               color: pink[600],
            },
         }}
      />
   );
}
