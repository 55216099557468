import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <path
       fill={color}
        d="M26.583 12.5h8.125l-9.375-9.375v8.125a1.25 1.25 0 001.25 1.25zm0 2.5a3.75 3.75 0 01-3.75-3.75V2.5h-8.75a3.75 3.75 0 00-3.75 3.75v7.252A7.5 7.5 0 0121.583 20v.157A5 5 0 0125.333 25v10c0 .91-.242 1.765-.67 2.5h6.92a3.75 3.75 0 003.75-3.75V15h-8.75zm3.75 16.25a1.25 1.25 0 01-2.5 0v-10a1.25 1.25 0 012.5 0v10zM9.083 20v2.5h-1.25a2.5 2.5 0 00-2.5 2.5v10a2.5 2.5 0 002.5 2.5h12.5a2.5 2.5 0 002.5-2.5V25a2.5 2.5 0 00-2.5-2.5h-1.25V20a5 5 0 10-10 0zm2.5 2.5V20a2.5 2.5 0 015 0v2.5h-5zm2.5 5.625a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75z"
      ></path>
    </svg>
  );
}

export default Icon;
