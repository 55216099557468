import React from "react";

function Icon({ color = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="40"
      fill="none"
      viewBox="0 0 41 40"
    >
      <path
        fill={color}
        d="M19.5 1.667L3.665 10v3.333h31.667V10m-8.334 6.666v11.667h5V16.667m-28.333 20h31.667v-5H3.666m13.333-15v11.666h5V16.667m-15 0v11.666h5V16.667H7z"
      ></path>
    </svg>
  );
}

export default Icon;
