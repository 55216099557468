/** @format */

import GuidingPrincipleCard from "../../components/GuidingPrincipleCard";
import HeroContainer from "../../components/HeroContainer";
import RenderCards from "../../components/RenderCards";

const valueData = [
  [
    {
      id: 1,
      icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Values/Client%20Centric%20Approach",
      title: "CLIENT CENTRIC APPROACH",
      subtitle:
        "We prioritize understanding and exceeding customer needs. By actively listening and adapting, we intend to build lasting relationships over quick wins.",
    },
    {
      id: 2,
      icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Values/Integrating%20Tech",
      title: "INTEGRATING TECH",
      subtitle:
        "We embrace innovation by integrating the latest tech. We promote adaptability, using digital tools to enhance efficiency and stay ahead in our evolving business landscape.",
    },
  ],
  [
    {
      id: 3,
      icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Values/Promote%20and%20Encourage%20Commitement",
      title: "PROMOTE AND ENCOURAGE COMMITMENT",
      subtitle:
        "We cultivate a sense of purpose and belonging. We recognize and reward dedication, valuing integrity and accountability toward our shared goals by dynamic performance assessment.",
    },
    {
      id: 4,
      icon: "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/Values/Traning%20and%20Development",
      title: "TRAINING AND DEVELOPMENT",
      subtitle:
        "We invest in continuous learning and foster curiosity. We empower our team to develop skills, ensuring we stay agile in a changing world.",
    },
  ],
];

export default function Index() {
  const isLastIndex = (index: number) => {
    return index === valueData.length - 1;
  };
  return (
    <div>
      <HeroContainer
        title="OUR VALUES"
        image={
          "https://res.cloudinary.com/dmxbffrod/image/upload/f_auto,q_auto/v1/Website/ourValuesBannerImage"
        }
        subtitle="Guided by integrity, driven by excellence, and committed to client success in every case."
      />

      <div className="mx-auto w-full md:mt-[10rem] mt-[0.7rem] mb-[5rem] flex flex-wrap justify-center md:gap-[10rem] gap-[3rem]">
        <RenderCards
          data={valueData}
          renderChildren={(card: {
            id: number;
            icon: string;
            title: string;
            subtitle: string;
          }) => {
            return (
              <GuidingPrincipleCard
                key={card.id}
                icon={card.icon}
                title={card.title}
                subtitle={card.subtitle}
              />
            );
          }}
        />
      </div>
    </div>
  );
}
